import React from 'react';
import { graphql } from 'gatsby';
import BlogPostPreview from '../BlogPostPreview/BlogPostPreview';
const BlogList = ({ posts, title = 'RECENTLY PUBLISHED' }) => {
  return (
    <div>
      <h4 className="title">{title}</h4>

      {posts.map(({ node }) => (
        <BlogPostPreview
          key={node.fields.slug}
          title={node.frontmatter.title}
          excerpt={node.excerpt}
          date={node.frontmatter.date}
          slug={node.fields.slug}
          published={node.frontmatter.published}
        />
      ))}
    </div>
  );
};

export default BlogList;
