import React from 'react';
import PropTypes from 'prop-types';
import StandardLayout from '../layouts/standard-layout';

import styled from 'styled-components';

import SEO from '../components/SEO';
import MaxWidthWrapper from '../components/MaxWidthWrapper';

// Components
import { Link, graphql } from 'gatsby';
import BlogList from '../components/BlogList/BlogList';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  console.log(data);
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`;

  return (
    <StandardLayout>
      <SEO title={`${tag} - George Nance`} description={tagHeader}></SEO>
      <MaxWidthWrapper>
        <div className="h-entry">
          <div>
            <BlogList title={tagHeader} posts={edges}></BlogList>
            {/* <ul>
              {edges.map(({ node }) => {
                const { slug } = node.fields
                const { title } = node.frontmatter
                return (
                  <li key={slug}>
                    <Link to={slug}>{title}</Link>
                  </li>
                )
              })}
            </ul> */}
          </div>
        </div>
      </MaxWidthWrapper>
    </StandardLayout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            published
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
